.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.opacity-25 {
  opacity: 25%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-75 {
  opacity: 75%;
}

.opacity-90 {
  opacity: 90%;
}

.opacity-10 {
  opacity: 10%;
}

.opacity-100 {
  opacity: 100%;
}

.bg-info {
  background-color: #00B0FF !important;
}

.font-blue {
  color: #00B0FF;
}

.background-gray {
  background-color: #d2d5d7;
}

.background-purple {
  background-color: #772ce8;
}
.background-purple :active {
  background-color: #531da4;
}
.background-purple :focus {
  background-color: #531da4;
}
.background-purple :hover {
  background-color: #531da4;
}

.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.gradient-box {
  height: 100px;
  background: linear-gradient(180deg, #fff, #d2d5d7);
}

.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.nav-tabs .nav-link.active {
  background-color: #d2d5d7;
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
}

.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.background-dark-gray {
  background-color: #2f2f2f !important;
}

.background-stay-purple {
  background-color: #772ce8;
}

.font-gray {
  color: rgba(0, 0, 0, 0.55);
}

.container-team {
  width: 25%;
  max-width: 25%;
}

.team-list-image {
  width: 320px;
  height: 220px;
}

.game-image {
  width: 360px;
  height: 100%;
}