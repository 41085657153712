@import "../abstract/abstract";

.opacity-25 {
  opacity: 25%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-75 {
  opacity: 75%;
}

.opacity-90 {
  opacity: 90%;
}

.opacity-10 {
  opacity: 10%;
}

.opacity-100 {
  opacity: 100%;
}

.bg-info {
  background-color: #00B0FF !important;
}

.font-blue {
  color: $ci-blue;
}

.background-gray {
  background-color: $gray
}

.background-purple {
  background-color: $purple;

  :active {
    background-color: $darkpuple;
  }

  :focus {
    background-color: $darkpuple;
  }

  :hover {
    background-color: $darkpuple;
  }
}